// src/components/filter.
import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';

//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';
import TableContainer from '../../components/Common/TableContainer';
import { Link } from "react-router-dom";

import { ref, db, onValue } from "../../firebase";

function DatatableTables() {
  const columns = useMemo(
    () => [
      {
        Header: 'Invoice No.',
        accessor: 'invoiceno',
      },
      {
        Header: 'Client',
        accessor: 'client'
      },
      {
        Header: 'Issued At',
        accessor: 'issuedate'
      },
      {
        Header: 'Total',
        accessor: 'total'
      },
      {
        Header: 'Status',
        accessor: 'status'
      },
    ],
    []
  );

  const [data, setData] = useState([]);

  //meta title
  document.title = "Dashboard | Invoice Management System";


  useEffect(() => {
    const reference = ref(db, 'invoices');
    onValue(reference, (snapshot) => {
      let invoices = snapshot.val();
      if(invoices){
        let temp = [];
        let keys= Object.keys(invoices);
        for(let i=0; i<keys.length; i++){
          temp.push(
            {
              invoiceno: <Link to={`/invoice/${keys[i]}`}>{invoices[keys[i]].invoiceDetail.invoiceno}</Link>,
              client: <Link to={`/invoice/${keys[i]}`}>{invoices[keys[i]].client}</Link>,
              issuedate: <Link to={`/invoice/${keys[i]}`}>{invoices[keys[i]].invoiceDetail.issuedatedisplay}</Link>,
              total: <Link to={`/invoice/${keys[i]}`}>{invoices[keys[i]].invoiceDetail.total}</Link>,
              status: <Link to={`/invoice/${keys[i]}`}>{invoices[keys[i]].status}</Link>,
            }
          );
        }
        setData(temp);
      }
      
    });
  });

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="All Invoices" breadcrumbItem="Dashboard" />
        <div className="d-flex justify-content-end">
          <Link to="/addinvoice" className="btn btn-primary">
            <i className="bx bx-plus"></i> New Invoice
          </Link>
        </div>
        <TableContainer
          columns={columns}
          data={data}
          isGlobalFilter={true}
          isAddOptions={false}
          customPageSize={10}
          className="custom-header-css"
        />
      </div>
    </div>
  );
}
DatatableTables.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default DatatableTables;