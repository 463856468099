import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { getDatabase, ref, onValue, set } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyDQQMTB1P4DUsfJwE93QxJJuF5wx5k2uwE",
  authDomain: "kolardb.firebaseapp.com",
  projectId: "kolardb",
  storageBucket: "kolardb.appspot.com",
  messagingSenderId: "779659960024",
  appId: "1:779659960024:web:00e6051260420f3337c1ad",
  measurementId: "G-6JLVCMPSKP"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth();
const db = getDatabase();

export { app, auth, db, signInWithEmailAndPassword, onValue, ref, set };