import React from "react";
import { Navigate } from "react-router-dom";

import UserProfile from "../pages/Authentication/user-profile";

import InvoiceDetail from "../pages/Invoices/invoices-detail";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

import Dashboard from "../pages/Dashboard/index";

import DatatableTables from "../pages/Tables/DatatableTables";

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  
  { path: "/profile", component: <UserProfile /> },
  
  { path: "/invoice/:id", component: <InvoiceDetail /> },
  { path: "/addinvoice", component: <InvoiceDetail /> },
  
  {
    path: "/",
    exact: true,
    component: < Navigate to="/dashboard" />,
  },
];

const publicRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },
];

export { authProtectedRoutes, publicRoutes };
